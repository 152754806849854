<template>
  <div class="section">
    <div class="d-flex justify-content-center pt-5 pb-3 bg-white" v-if="isLoading">
      <b-spinner />
    </div>
    <vue-good-table v-else :columns="columns" :rows="stocks" :fixed-header="false" :sort-options="{
      enabled: false,
    }">
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />
      </template>

      <template slot="table-row" slot-scope="props">
        <!-- Column: Stock -->
        <span v-if="props.column.field === 'jumlah'">
          <span>{{ props.row.stock }} {{ props.row.unit }}</span>
        </span>

        <span v-if="props.column.field === 'product_type'">
          <span>{{
            props.row.product_type === "parent"
              ? "Produk Induk"
              : "Produk Eceran"
          }}</span>
        </span>

        <!-- Column: name -->
        <span v-else-if="props.column.field === 'name'">
          <span>{{ props.row.name || "-" }}</span>
        </span>

        <!-- Column Produk Induk -->
        <!-- <span v-else-if="props.column.field === 'parent_product.name'">
          <span>{{ props.row.parent_product ? props.row.parent_product.name || '-' : '-' }}</span>
        </span> -->

        <!-- Column: Lokasi SKU -->
        <span v-else-if="
          props.column.field === 'stock_in_supplier' ||
          props.column.field === 'stock_in_warehouse'
        ">
          <!-- <span>{{ props.row.stock_in_supplier || '-' }}</span> -->
          <div v-if="props.row.stock_in_supplier">
            <span v-if="props.row.stock_in_supplier.length < 3">{{
              props.row.stock_in_supplier
                .map((e) => e.name)
                .slice(0, 3)
                .join(", ")
            }}</span>
            <b-dropdown v-else :text="props.row.stock_in_supplier
              .map((e) => e.name)
              .slice(0, 3)
              .join(', ')
              " variant="flat-secondary" class="delivery-numbers px-0">
              <b-row class="mx-0 pt-1">
                <b-col v-for="(item, index) in props.row.stock_in_supplier" :key="index" class="">
                  <h6 class="text-dark">{{ item.name }}</h6>
                </b-col>
              </b-row>
            </b-dropdown>
          </div>
          <div v-if="props.row.stock_in_warehouse">
            <span v-if="props.row.stock_in_warehouse.length < 3">{{
              props.row.stock_in_warehouse
                .map((e) => e.name)
                .slice(0, 3)
                .join(", ")
            }}</span>
            <b-dropdown v-else :text="props.row.stock_in_warehouse
              .map((e) => e.name)
              .slice(0, 3)
              .join(', ')
              " variant="flat-secondary" class="delivery-numbers px-0">
              <b-row class="mx-0 pt-1">
                <b-col v-for="(item, index) in props.row.stock_in_warehouse" :key="index" class="">
                  <h6 class="text-dark">{{ item.name }}</h6>
                </b-col>
              </b-row>
            </b-dropdown>
          </div>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <div class="text-right">
            <b-button class="detail__button py-1 mr-25" style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04)"
              @click="getDetail(props.row.uuid)" v-if="
                isWarehouse
                  ? checkPermission('detail aktual stok gudang')
                  : checkPermission('detail aktual stok supplier')
              ">
              Lihat Detail
            </b-button>
            <b-button v-if="props.row.stock < 0" class="detail__button py-1"
              style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04)" @click="
                $router.push({
                  name: 'stock.opname',
                  query: { ctaId: props.row.uuid, ctaName: props.row.name },
                })
                ">
              Re-stock
            </b-button>
          </div>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div v-if="rows" class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem">
      <div class="d-flex align-items-center">
        <b-form-select v-model="filter.per_page" :options="['10', '15', '20']" class="mx-1" />
        <span class="text-nowrap">
          Tampilkan
          {{
            rows.total > 0 ? filter.per_page * (rows.current_page - 1) + 1 : 0
          }}
          ke
          {{
            rows.total > 0
              ? Math.min(filter.per_page * rows.current_page, rows.total)
              : 0
          }}
          dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination class="mb-0" :data="rows" :limit="4" align="right" @pagination-change-page="getStockActual" />
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalDetailStock :detail="detail" />
  </div>
</template>

<script>
import {
  BButton,
  BFormSelect,
  VBToggle,
  BRow,
  BCol,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

import { VueGoodTable } from "vue-good-table";

import ModalDetailStock from "@/components/Stock/DetailStock.vue";

export default {
  components: {
    VueGoodTable,
    BButton,
    BSpinner,
    BFormSelect,
    ModalDetailStock,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    resultActual: {
      type: Object,
    },
    getStockActual: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
    },
    isWarehouse: {
      type: Boolean,
    },
    isSupplier: {
      type: Boolean,
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      columns: [
        {
          label: "Nama Produk",
          field: "name",
        },
        {
          label: "Tipe Produk",
          field: "product_type",
        },
        {
          label: "Spesifikasi",
          field: "specification",
        },
        {
          label: "Lokasi SKU",
          field: "stock_in_supplier" || "stock_in_warehouse",
        },
        {
          label: "Jumlah",
          field: "jumlah",
        },
        // {
        //   label: 'Nama Produk Induk',
        //   field: 'parent_product.name',
        // },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: {},
      stocks: [],
      filter: {
        per_page: 10,
      },
      detail: {},
    };
  },
  watch: {
    resultActual: {
      handler(value) {
        this.rows = value;
        this.stocks = value.data;
      },
      deep: true,
    },
    filter: {
      handler(value) {
        this.$emit("filterStockActual", value);
      },
      deep: true,
    },
  },
  mounted() {
    this.rows = this.resultActual;
    this.stocks = this.resultActual.data;
  },
  methods: {
    async getDetail(id) {
      await this.$store
        .dispatch(
          this.$store.state.stockWarehouse.isWarehouse === true
            ? "stockWarehouse/getStockActual"
            : "stockWarehouse/getStockActualSupplier",
          {
            uuid: id,
            params: "",
          }
        )
        .then((result) => {
          this.detail = result.data.data;
          this.$bvModal.show("modal-stock-actual");
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

// Timeline
.timeline-variant-success .timeline-item-point:before {
  background-color: none;
  background: linear-gradient(180deg, #45b6ab 0%, rgba(69, 182, 171, 0.5) 100%);
  box-shadow: inset 1.67273px 1.67273px 3.34545px rgb(230 219 211 / 20%);
}

// .vgt-responsive {
//   height: 68vh;
//   background-color: #fff;
// }

.detail__button {
  background-color: #ffffff !important;
  border: 1px solid #e4e5ec !important;
  color: $dark !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

#modal-stock-actual {
  .modal-dialog {
    max-width: 100%;
    margin: 0;

    .modal-content {
      height: 100vh;

      .modal-body {
        padding: 0;
        overflow-y: scroll;

        .progress-bar-success {
          background-color: #eff1f5;

          .progress-bar {
            background: linear-gradient(269.89deg,
                #6cdfd4 0%,
                #45b6ab 98.81%) !important;
            box-shadow: 0px 6px 8px rgba(44, 129, 120, 0.06);
            border-radius: 20px;
          }
        }

        label {
          font-size: 14px;
          color: $dark;
          font-weight: 600;
        }

        .vs__open-indicator {
          fill: rgba(60, 60, 60, 0.5);
        }

        .custom__input {
          height: 52px;
          background: #eff1f5;
          border: 1px solid #e4e5ec;
          border-radius: 8px;
        }

        .vs__dropdown-toggle {
          height: 52px;
          background-color: #eff1f5;
          border: 1px solid #e4e5ec;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
