var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-content-center mb-1 mt-3"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_c('vue-good-table',{attrs:{"max-height":"80vh","columns":_vm.columns,"rows":_vm.stocks,"fixed-header":false,"sort-options":{
    enabled: false,
  },"pagination-options":{
      enabled: false,
    }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field == 'action')?_c('span'):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'delivery_numbers')?_c('span',{attrs:{"id":"delivery_numbers"}},[(props.row.delivery_numbers)?_c('div',[(props.row.delivery_numbers.length < 3)?_c('span',[_vm._v(_vm._s(props.row.delivery_numbers .map(function (e) { return e.delivery_number_note; }) .slice(0, 3) .join(", ")))]):_c('b-dropdown',{staticClass:"px-0",attrs:{"text":props.row.delivery_numbers
              .map(function (e) { return e.delivery_number_note; })
              .slice(0, 3)
              .join(', '),"variant":"flat-secondary"}},[_c('b-row',{staticClass:"mx-0 pt-1"},_vm._l((props.row.delivery_numbers),function(item,index){return _c('b-col',{key:index},[_c('h6',{staticClass:"text-dark"},[_vm._v(_vm._s(item.delivery_number_note))])])}),1)],1)],1):_vm._e()]):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{staticClass:"d-flex",attrs:{"id":"dropdown-dropleft","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){_vm.$store.commit('stockWarehouse/setIsWarehouse', false),
              _vm.$router.push({
                name: 'stock.gudang.detail',
                params: { id: props.row.uuid },
              }),
              _vm.$store.commit('stockWarehouse/setIsDeliveryNumbers', true)}}},[_c('span',[_vm._v(" Ubah ")])]),(_vm.checkPermission('batalkan produk masuk supplier'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteData(props.row.uuid)}}},[_c('span',[_vm._v(" Hapus ")])]):_vm._e()],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),(_vm.rows && _vm.isLoading == false)?_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap bg-white border",staticStyle:{"padding":"0.8rem"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '15', '20']},model:{value:(_vm.filter.per_page),callback:function ($$v) {_vm.$set(_vm.filter, "per_page", $$v)},expression:"filter.per_page"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" Tampilkan "+_vm._s(_vm.rows.total > 0 ? _vm.filter.per_page * (_vm.rows.current_page - 1) + 1 : 0)+" ke "+_vm._s(_vm.rows.total > 0 ? Math.min(_vm.filter.per_page * _vm.rows.current_page, _vm.rows.total) : 0)+" dari "+_vm._s(_vm.rows.total)+" transaksi. ")])],1),_c('div',[_c('b-row',{staticClass:"mx-0 align-items-center"},[_c('b-col',[_c('pagination',{staticClass:"mb-0",attrs:{"data":_vm.rows,"limit":4,"align":"right"},on:{"pagination-change-page":_vm.getData}})],1)],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }